import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { Sentry } from 'services/sentry';
import { login } from 'services/api/auth/login';
import { logout } from 'services/api/auth/logout';
import { routes } from 'services/routes';
import { loginUser, logoutUser } from './actions';

export function* loginUserSaga(action: ActionType<typeof loginUser.request>) {
  try {
    // eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-assignment
    const token: string | undefined = yield call(login, action.payload);

    if (token === undefined) throw new Error('No token in login response body'); // intl?!

    yield put(loginUser.success({ token }));

    /* istanbul ignore next */
    Sentry.configureScope((scope: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      scope.setUser({
        email: action.payload.email,
        ...jwtDecode(token),
      });
    });
    yield Router.push('/');
  } catch (error: unknown) {
    let errorMessage = `Error: ${String(error)}`;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(loginUser.failure({ errorMessage }));
  }
}
export function* logoutUserSaga() {
  yield call(logout);
  yield Router.push(routes.LOGIN);
}

export default function* loginSagas() {
  yield takeEvery(getType(loginUser.request), loginUserSaga);
  yield takeEvery(logoutUser.type, logoutUserSaga);
}
