import { apiRoutes } from '../apiRoutes';
import { authApiClient, TOKEN_KEY } from '../client';

interface LoginData {
  email: string;
  password: string;
}
export const login = async (data: LoginData): Promise<string | undefined> => {
  const result = await authApiClient.post<{ token?: string; access?: string }>(
    apiRoutes.login,
    data,
  );
  const token: string | undefined = result.data.token ?? result.data.access;
  if (token !== undefined) localStorage.setItem(TOKEN_KEY, token);
  return token;
};
