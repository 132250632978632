import { Context } from 'next-redux-wrapper';
import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleWare, { Task } from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import rootReducers, { rootInitialState } from './reducers';
import rootSagas from './sagas';
import { RootState } from './types';

type ReduxSagaStore<S> = S & { sagaTask: Task };

const persistConfig = {
  key: 'root',
  whitelist: ['home', 'product', 'condition'],
  storage, // localStorage
};

/* istanbul ignore next */
const bindMiddleware = (middleware: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const configureStore = (
  context: Context | undefined,
  initialState: RootState = rootInitialState,
): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleWare();
  const isServer = typeof window === 'undefined';
  let store: Store<RootState>;

  if (isServer) {
    store = createStore(rootReducers, initialState, bindMiddleware([sagaMiddleware]));
  } else {
    store = createStore(
      persistReducer(persistConfig, rootReducers),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      initialState,
      bindMiddleware([sagaMiddleware]),
    );
  }

  const reduxSagaStore: ReduxSagaStore<typeof store> = {
    ...store,
    sagaTask: sagaMiddleware.run(rootSagas),
  };
  return reduxSagaStore;
};

export const appWrapper = createWrapper<RootState>(configureStore);

export default configureStore;
