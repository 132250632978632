export const routes = {
  HOME: '/',
  BY_CONDITION: '/by-condition',
  BY_CONDITION_PREVENTION: '/by-condition/prevention',
  BY_CONDITION_OEDEMA: '/by-condition/oedema',
  BY_CONDITION_ACTIVE_LEG_ULCER: '/by-condition/active-leg-ulcer',
  BY_CONDITION_ACTIVE_LEG_ULCER_SYMPTOMS: '/by-condition/active-leg-ulcer/symptoms',
  BY_CONDITION_ACTIVE_LEG_ULCER_SELF_CARE: '/by-condition/active-leg-ulcer/self-care',
  BY_CONDITION_ACTIVE_LEG_ULCER_RED_FLAG_CAUTION: '/by-condition/active-leg-ulcer/red-flag-caution',
  BY_CONDITION_OEDEMA_SKIN_FOLDS: '/by-condition/oedema/skin-folds',
  BY_PRODUCT_READY_TO_WEAR: '/by-product/ready-to-wear',
  BY_PRODUCT_SELECT_CLASS: '/by-product/class',
  BY_PRODUCT_SELECT_SIZE_CATEGORY: '/by-product/size-category',
  BY_PRODUCT: '/by-product',
  KTWO: '/ktwo',
  LOGIN: '/login',
  MADE_TO_MEASURE: '/made-to-measure',
  NO_URGO_PRODUCT: '/no-urgo-product',
  OPTIONS: '/options',
  RECOMMENDED_PRODUCTS: '/recommended-products',
  REORDER_PRODUCT: '/reorder-product',
  GENERATE_PDF: '/generate-pdf',
} as const;
