import { COMPRESSION_REASONS } from 'components/pages/byCondition/constants';
import { OEDEMA_TYPE } from 'components/pages/oedema/constants';
import { ADDITIONAL_CONDITIONS } from 'components/pages/prevention/constants';
import { Brand } from 'redux/ProductInfo/types';

export const PREVENTION_REOCCURRENCE = 'PREVENTION_REOCCURRENCE';

export type CompressionReasonType = COMPRESSION_REASONS | typeof PREVENTION_REOCCURRENCE;

export interface ConditionState {
  compressionReason?: CompressionReasonType;
  preventionReason?: ADDITIONAL_CONDITIONS;
  filteredProducts: Brand[];
  oedemaLevel?: OEDEMA_TYPE;
  selfCare?: boolean;
  skinFolds?: boolean;
}

export const CLEAR_CONDITION = 'CLEAR_CONDITION';
export const SET_COMPRESSION_REASON = 'SET_COMPRESSION_REASON';
export const SET_PREVENTION_REASON = 'SET_PREVENTION_REASON';
export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS';
export const SET_OEDEMA_LEVEL = 'SET_OEDEMA_LEVEL';
export const SET_SELF_CARE = 'SET_SELF_CARE';
export const SET_SKIN_FOLDS = 'SET_SKIN_FOLDS';

interface ClearCondition {
  type: typeof CLEAR_CONDITION;
}

interface SetCompressionReason {
  type: typeof SET_COMPRESSION_REASON;
  payload: CompressionReasonType;
}

interface SetPreventionReason {
  type: typeof SET_PREVENTION_REASON;
  payload: ADDITIONAL_CONDITIONS;
}

interface SetFilteredProducts {
  type: typeof SET_FILTERED_PRODUCTS;
  payload: Brand[];
}

interface SetOedemaLevel {
  type: typeof SET_OEDEMA_LEVEL;
  payload: OEDEMA_TYPE;
}

interface SetSelfCare {
  type: typeof SET_SELF_CARE;
  payload: boolean;
}
interface SetSkinFolds {
  type: typeof SET_SKIN_FOLDS;
  payload: boolean;
}

export type ConditionActionTypes =
  | ClearCondition
  | SetCompressionReason
  | SetPreventionReason
  | SetFilteredProducts
  | SetSelfCare
  | SetSkinFolds
  | SetOedemaLevel;
