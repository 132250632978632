import { OEDEMA_TYPE } from 'components/pages/oedema/constants';
import { ADDITIONAL_CONDITIONS } from 'components/pages/prevention/constants';
import { Brand } from 'redux/ProductInfo/types';
import { RootState } from 'redux/types';
import { createSelector } from 'reselect';
import { CompressionReasonType, ConditionState } from './types';

export const selectConditionState = (state: RootState): ConditionState => state.condition;

export const selectCompressionReason = createSelector(
  selectConditionState,
  (conditionState: ConditionState): CompressionReasonType | undefined =>
    conditionState.compressionReason,
);

export const selectPreventionReason = createSelector(
  selectConditionState,
  (conditionState: ConditionState): ADDITIONAL_CONDITIONS | undefined =>
    conditionState.preventionReason,
);

export const selectFilteredProducts = createSelector(
  selectConditionState,
  (conditionState: ConditionState): Brand[] => conditionState.filteredProducts,
);
export const selectOedemaLevel = createSelector(
  selectConditionState,
  (conditionState: ConditionState): OEDEMA_TYPE | undefined => conditionState.oedemaLevel,
);

export const selectSelfCare = createSelector(
  selectConditionState,
  (conditionState: ConditionState): boolean | undefined => conditionState.selfCare,
);
export const selectSkinFolds = createSelector(
  selectConditionState,
  (conditionState: ConditionState): boolean | undefined => conditionState.skinFolds,
);
