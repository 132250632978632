import nextCookie from 'next-cookies';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { useStore } from 'react-redux';
import Head from 'next/head';
import { Root } from 'components/pages/_app/Root';
import { appWrapper } from 'redux/store';
import { captureException } from 'services/sentry';
import { theme } from 'stylesheet';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { AppWrapper } from './_app_wrapper';

interface CustomAppProps {
  hasError: boolean;
  errorEventId?: string;
}

type MyAppProps = AppProps & CustomAppProps;
type MyAppInitialProps = AppInitialProps & CustomAppProps;

const MyApp = (props: MyAppProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { Component, pageProps, hasError, errorEventId } = props;
  const store = useStore();
  const APP_TITLE = 'Measure & Go - Find the best hosiery for your patients';

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <title>{APP_TITLE}</title>
      </Head>
      <Root hasError={hasError} errorEventId={errorEventId}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <PersistGate persistor={persistStore(store)}>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </PersistGate>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </Root>
    </>
  );
};

App.getInitialProps = async (props: AppContext): Promise<MyAppInitialProps> => {
  const { Component, ctx } = props;

  try {
    const pageProps =
      Component.getInitialProps !== undefined ? await Component.getInitialProps(ctx) : {};
    return { pageProps, hasError: false, errorEventId: undefined };
  } catch (error) {
    // Capture errors that happen during a page's getInitialProps.
    // This will work on both client and server sides.
    const errorEventId = captureException(error, ctx);
    return {
      hasError: true,
      errorEventId,
      pageProps: {},
    };
  }
};

export default appWrapper.withRedux(MyApp);
