export const SET_TRANSLATOR_MODE = 'SET_TRANSLATOR_MODE';

interface SetTranslatorModeAction {
  type: typeof SET_TRANSLATOR_MODE;
  payload: TranslatorModeState;
}

export type TranslatorModeState = boolean;

export type TranslatorModeActionTypes = SetTranslatorModeAction;
