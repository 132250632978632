import { Brand } from 'redux/ProductInfo/types';
import {
  CLEAR_CONDITION,
  ConditionActionTypes,
  ConditionState,
  SET_COMPRESSION_REASON,
  SET_FILTERED_PRODUCTS,
  SET_OEDEMA_LEVEL,
  SET_PREVENTION_REASON,
  SET_SELF_CARE,
  SET_SKIN_FOLDS,
} from './types';

export const initialState: ConditionState = {
  compressionReason: undefined,
  filteredProducts: [Brand.URGOKTWO, Brand.ALTIVEN, Brand.ALTIFORM, Brand.ALTIPRESS],
};

const conditionReducer = (
  state: ConditionState = initialState,
  action: ConditionActionTypes,
): ConditionState => {
  const typedAction = action;
  switch (typedAction.type) {
    case CLEAR_CONDITION:
      return {
        ...initialState,
      };
    case SET_COMPRESSION_REASON:
      return {
        ...state,
        compressionReason: typedAction.payload,
      };
    case SET_PREVENTION_REASON:
      return {
        ...state,
        preventionReason: typedAction.payload,
      };
    case SET_FILTERED_PRODUCTS:
      return {
        ...state,
        filteredProducts: typedAction.payload,
      };
    case SET_OEDEMA_LEVEL:
      return {
        ...state,
        oedemaLevel: typedAction.payload,
      };
    case SET_SELF_CARE:
      return {
        ...state,
        selfCare: typedAction.payload,
      };
    case SET_SKIN_FOLDS:
      return {
        ...state,
        skinFolds: typedAction.payload,
      };
    default:
      return state;
  }
};

export default conditionReducer;
