export const ACCEPT_USER_AGREEMENT = 'ACCEPT_USER_AGREEMENT';
export const SET_OFFLINE_READY = 'SET_OFFLINE_READY';

interface SetOfflineReady {
  type: typeof SET_OFFLINE_READY;
}

interface AcceptUserAgreementAction {
  type: typeof ACCEPT_USER_AGREEMENT;
}

export interface HomePageState {
  lastUserAgreementTimestamp?: number;
  offlineReady: boolean;
}

export type HomeActionTypes = AcceptUserAgreementAction | SetOfflineReady;
