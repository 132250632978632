import { AppCrashFallback } from 'components/AppCrashFallback';
import { FunctionComponent } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTranslatorMode } from 'redux/TranslatorMode';

import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import { CSSResets } from './CSSResets';
import { ErrorBoundary } from './ErrorBoundary';

const locales = {
  en: flattenMessages(enMessages),
};

interface RootProps {
  hasError: boolean;
  errorEventId?: string;
}

export const Root: FunctionComponent<RootProps> = props => {
  const isTranslatorModeEnabled = useSelector(selectTranslatorMode);

  return (
    <ErrorBoundary
      FallbackComponent={AppCrashFallback}
      hasError={props.hasError}
      eventId={props.errorEventId}
    >
      <IntlProvider
        locale="en"
        messages={isTranslatorModeEnabled ? {} : locales.en}
        onError={isTranslatorModeEnabled ? () => '' : undefined}
      >
        <CSSResets isTranslatorModeEnabled={isTranslatorModeEnabled} />
        {props.children}
      </IntlProvider>
    </ErrorBoundary>
  );
};
