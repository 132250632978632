import { RootState } from 'redux/types';
import { createSelector } from 'reselect';
import { M2MProduct, RTWProduct } from 'services/hooks/useDownloadProducts';
import {
  AltipressStyleOptions,
  BandageInfo,
  Brand,
  CompressionClass,
  MeasurementsInfo,
  ProductInfo,
  ProductLengths,
  ProductSizes,
  SizeCategoryType,
  StyleOptions,
} from './types';

export const selectProduct = (state: RootState): ProductInfo => state.product;

export const selectProductBrand = createSelector(
  selectProduct,
  (product: ProductInfo): Brand | undefined => product.brand,
);

export const selectCompressionClass = createSelector(
  selectProduct,
  (product: ProductInfo): CompressionClass | undefined => product.compressionClass,
);

export const selectSizeCategory = createSelector(
  selectProduct,
  (product: ProductInfo): SizeCategoryType | undefined => product.sizeCategory,
);

export const selectStyleOptions = createSelector(
  selectProduct,
  (product: ProductInfo): StyleOptions | undefined => product.styleOptions,
);

export const selectAltipressStyleOptions = createSelector(
  selectProduct,
  (product: ProductInfo): AltipressStyleOptions | undefined => product.altipressStyleOptions,
);

export const selectBandageInfo = createSelector(
  selectProduct,
  (product: ProductInfo): BandageInfo | undefined => product.bandageInfo,
);

export const selectMeasurements = createSelector(
  selectProduct,
  (product: ProductInfo): MeasurementsInfo | undefined => product.measurements,
);

export const selectProductSizes = createSelector(
  selectProduct,
  (product: ProductInfo): ProductSizes => product.productSizes,
);

export const selectProductLengths = createSelector(
  selectProduct,
  (product: ProductInfo): ProductLengths => product.productLengths,
);

export const selectRealProduct = createSelector(
  selectProduct,
  (product: ProductInfo): RTWProduct | M2MProduct | undefined => product.realProduct,
);

export const selectQuantity = createSelector(
  selectProduct,
  (product: ProductInfo): number | undefined => product.quantity,
);
