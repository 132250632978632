import { css } from 'styled-components';
import { createTheme } from '@material-ui/core/styles';

/**
 * This file is here to ensure UI consistency
 * You **MUST** sync with your designer at the start of the project to check
 * what colors/typos/grid unit you are going to use, and stick to it.
 */

// This file is where the variables are defined, so we can disable stylelint here
// stylelint-disable

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 * and elements with fixed width/height
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';
export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

const URGO_COLORS = {
  blue: '#3CA4C3',
  blueBright: '#00A7C9',
  blueRoyal: '#669CDA',
  blueDark: '#013768',
  orangePastel: '#FFBAA1',
  greenPastel: '#8ECEC0',
  greenDark: '#6D8C91',
};
/**
 * Use this palette in your components
 * If a new color is in the mockups, check with the designer
 * that the project really need a new color and add it here.
 * As to naming, the best name is the name used by the designer
 */
export const colorPalette = {
  primary: URGO_COLORS.blue,
  secondary: URGO_COLORS.blueBright,
  white: '#FFFFFF',

  blue: URGO_COLORS.blue,
  blueBright: URGO_COLORS.blueBright,
  blueRoyal: URGO_COLORS.blueRoyal,
  blueDark: URGO_COLORS.blueDark,
  bluePastel: '#AEE1E1',
  orangePastel: URGO_COLORS.orangePastel,
  greenPastel: URGO_COLORS.greenPastel,
  greenDark: URGO_COLORS.greenDark,
  yellowPastel: '#fffdd0',
  yellowDark: '#ffcc29',

  // TODO update these (do we need that many?)
  greyLight: '#E0E0E0',
  greyDark: '#222',
  amber: '#FFC107',
  amberDark: '#FF8F00',
  blueLight: '#F4F9FB',
  red: '#FF7373',
  blackTransparent: 'rgba(0, 0, 0, 0.24)',
  darkGrey: '#2F4758',
  lightGrey: 'rgba(107,114,128,1)',
  lightGreen: '#00FE93',
  green: '#8ED441',
};

export const fontFamily = {
  main: `'Lato', 'Helvetica', 'Arial', sans-serif`,
  code: 'Monospace',
};

export const typography = {
  main: css`
    font-family: ${fontFamily.main};
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: ${colorPalette.greyDark};
  `,
  bold: css`
    font-weight: bold;
  `,
  light: css`
    font-weight: lighter;
  `,
  small: css`
    font-size: 14px;
  `,
  h1: css`
    font-family: ${fontFamily.main};
    font-weight: bold;
    font-size: 24px;
  `,
  code: css`
    font-family: ${fontFamily.code};
    color: ${colorPalette.amberDark};
  `,
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    tablet: true; // adds the `tablet` breakpoint
    desktop: true;
  }
}

export const TABLET_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;

export const ONE_COLUMN_WIDTH = '650px';

export const breakpoints = {
  tablet: `${TABLET_WIDTH}px`,
  desktop: `${DESKTOP_WIDTH}px`,
};

export const theme = createTheme({
  breakpoints: {
    values: {
      tablet: TABLET_WIDTH,
      desktop: DESKTOP_WIDTH,
    },
  },
  palette: {
    primary: {
      main: colorPalette.primary,
    },
    secondary: {
      main: colorPalette.secondary,
    },
    text: {
      primary: colorPalette.darkGrey,
      secondary: colorPalette.lightGrey,
    },
  },
  typography: {
    h2: {
      fontSize: '20px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiAccordion: {
      root: {
        margin: '0 auto',
        '&$expanded': {
          margin: '16px auto',
        },
      },
    },
  },
});

theme.typography.body1 = {
  fontSize: '0.875rem',
  [theme.breakpoints.up('tablet')]: {
    fontSize: '1rem',
  },
};

theme.typography.body2 = {
  fontSize: '0.7rem',
  [theme.breakpoints.up('tablet')]: {
    fontSize: '0.875rem',
  },
};
