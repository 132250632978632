import { FunctionComponent, ReactNode, useEffect } from 'react';
import Router from 'next/router';
import { useDispatch } from 'react-redux';
import { setOfflineReady } from 'redux/Home';

interface AppWrapperProps {
  children: ReactNode;
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const AppWrapper: FunctionComponent<AppWrapperProps> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // Reeset scroll position
    Router.events.on('routeChangeComplete', () => {
      window !== undefined && window.scrollTo(0, 0);
    });
  }, []);

  // https://web.dev/customize-install/
  useEffect(() => {
    function handleReadyToInstall(e: Event) {
      e.preventDefault();
      dispatch(setOfflineReady());
    }

    window.addEventListener('beforeinstallprompt', handleReadyToInstall);

    return () => window.removeEventListener('beforeinstallprompt', handleReadyToInstall);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    window.dataLayer = window.dataLayer || [];
  });

  return <div id="#jss-server-side">{children}</div>;
};
