import { ACCEPT_USER_AGREEMENT, HomeActionTypes, SET_OFFLINE_READY } from './types';

export const acceptUserAgreement = (): HomeActionTypes => ({
  type: ACCEPT_USER_AGREEMENT,
});

export const setOfflineReady = (): HomeActionTypes => ({
  type: SET_OFFLINE_READY,
});

export default { acceptUserAgreement, setOfflineReady };
