import { combineReducers, Reducer } from 'redux';

import { reducer as login, initialState as loginInitialState } from './Login';
import { reducer as product, initialState as productInitialState } from './ProductInfo';
import { reducer as condition, initialState as conditionInitialState } from './Condition';
import { reducer as home, initialState as homeInitialState } from './Home';
import {
  reducer as translatorMode,
  initialState as translatorModeInitialState,
} from './TranslatorMode';

import { RootState } from './types';

/**
 * Example of the Login module which should export a reducer.
 */

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

export const rootInitialState: RootState = {
  login: loginInitialState,
  product: productInitialState,
  condition: conditionInitialState,
  home: homeInitialState,
  translatorMode: translatorModeInitialState,
};

const rootReducers: Reducer<RootState> = combineReducers({
  login,
  product,
  home,
  translatorMode,
  condition,
});

export default rootReducers;
