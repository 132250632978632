import { M2MProduct, RTWProduct } from 'services/hooks/useDownloadProducts';
import {
  AltipressStyleOptions,
  BandageInfo,
  Brand,
  CLEAR_BRAND,
  CLEAR_PRODUCT_INFO,
  CLEAR_SIZE_CATEGORY,
  CompressionClass,
  MadeToMeasureMeasurementsPayload,
  ProductInfoActionTypes,
  ProductLengths,
  ProductSizes,
  ReadyToWearMeasurementsPayload,
  SET_ALTIPRESS_STYLE_OPTIONS,
  SET_BANDAGE_INFO,
  SET_BRAND,
  SET_COMPRESSION_CLASS,
  SET_MADE_TO_MEASURE_MEASUREMENTS,
  SET_PRODUCT_LENGTHS,
  SET_PRODUCT_SIZES,
  SET_QUANTITY,
  SET_READY_TO_WEAR_MEASUREMENTS,
  SET_REAL_PRODUCT,
  SET_SIZE_CATEGORY,
  SET_STYLE_OPTIONS,
  SizeCategoryType,
  StyleOptions,
} from './types';

export const clearProductInfo = (): ProductInfoActionTypes => ({
  type: CLEAR_PRODUCT_INFO,
});

export const setBrand = (brand: Brand): ProductInfoActionTypes => ({
  type: SET_BRAND,
  payload: brand,
});

export const clearBrand = (): ProductInfoActionTypes => ({
  type: CLEAR_BRAND,
});

export const setSizeCategory = (categorySize: SizeCategoryType): ProductInfoActionTypes => ({
  type: SET_SIZE_CATEGORY,
  payload: categorySize,
});

export const clearSizeCategory = (): ProductInfoActionTypes => ({
  type: CLEAR_SIZE_CATEGORY,
});

export const setStyleOptions = (styleOptions: StyleOptions): ProductInfoActionTypes => ({
  type: SET_STYLE_OPTIONS,
  payload: styleOptions,
});

export const setAltipressStyleOptions = (
  styleOptions: AltipressStyleOptions,
): ProductInfoActionTypes => ({
  type: SET_ALTIPRESS_STYLE_OPTIONS,
  payload: styleOptions,
});

export const setReadyToWearMeasurements = (
  measurements: ReadyToWearMeasurementsPayload,
): ProductInfoActionTypes => ({
  type: SET_READY_TO_WEAR_MEASUREMENTS,
  payload: measurements,
});

export const setMadeToMeasureMeasurements = (
  measurements: MadeToMeasureMeasurementsPayload,
): ProductInfoActionTypes => ({
  type: SET_MADE_TO_MEASURE_MEASUREMENTS,
  payload: measurements,
});

export const setProductSizes = (sizes: ProductSizes = []): ProductInfoActionTypes => ({
  type: SET_PRODUCT_SIZES,
  payload: sizes,
});

export const setProductLengths = (lengths: ProductLengths = []): ProductInfoActionTypes => ({
  type: SET_PRODUCT_LENGTHS,
  payload: lengths,
});

export const setCompressionClass = (
  compressionClass: CompressionClass,
): ProductInfoActionTypes => ({
  type: SET_COMPRESSION_CLASS,
  payload: compressionClass,
});

export const setBandageInfo = (bandageInfo: BandageInfo): ProductInfoActionTypes => ({
  type: SET_BANDAGE_INFO,
  payload: bandageInfo,
});

export const setRealProduct = (realProduct: RTWProduct | M2MProduct): ProductInfoActionTypes => ({
  type: SET_REAL_PRODUCT,
  payload: realProduct,
});

export const setQuantity = (quantity: number): ProductInfoActionTypes => ({
  type: SET_QUANTITY,
  payload: quantity,
});

export default {
  setBrand,
  clearBrand,
  setStyleOptions,
  setCompressionClass,
  setSizeCategory,
  setBandageInfo,
  setReadyToWearMeasurements,
  setProductSizes,
  setProductLengths,
  setRealProduct,
  setQuantity,
};
