import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { apiRoutes } from './apiRoutes';

interface AccessToken {
  exp: number;
}

export const TOKEN_KEY = 'token';

const backendBaseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

const tokenHasExpired = (token: AccessToken): boolean => {
  // Less than 10 seconds remaining => token has expired
  const now = new Date().getTime() / 1000;
  return token.exp - now < 10;
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const updateToken = (token: string) => {
  return localStorage.setItem(TOKEN_KEY, token);
};

const refreshToken = async () => {
  // use axios.post instead of authApiClient.post to exclude interceptor
  const response = await axios.post<{ access: string }>(
    `${backendBaseUrl}${apiRoutes.refresh}`,
    {},
  );
  updateToken(response.data.access);
};

const checkToken = async () => {
  const token = getToken();

  // There was no token to begin with, nothing to check.
  if (token === null) return;

  try {
    const parsedToken = jwtDecode<AccessToken>(token);
    if (tokenHasExpired(parsedToken)) {
      await refreshToken();
    }
  } catch (_) {
    // Token was invalid, logging out the user.
    updateToken('');
    // LOGOUT
  }
};

export const authApiClient = axios.create({ baseURL: backendBaseUrl, withCredentials: true });
export const apiClient = axios.create({ baseURL: backendBaseUrl });

authApiClient.interceptors.request.use(
  async config => {
    // Do something before request is sent
    await checkToken();

    const token = getToken();
    if (token !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);
