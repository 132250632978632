import { ACCEPT_USER_AGREEMENT, HomeActionTypes, HomePageState, SET_OFFLINE_READY } from './types';

export const initialState: HomePageState = {
  offlineReady: false,
};

const homePageReducer = (
  state: HomePageState = initialState,
  action: HomeActionTypes,
): HomePageState => {
  const typedAction = action;
  switch (typedAction.type) {
    case ACCEPT_USER_AGREEMENT:
      return {
        ...state,
        lastUserAgreementTimestamp: Date.now(),
      };
    case SET_OFFLINE_READY:
      return {
        ...state,
        offlineReady: true,
      };
    default:
      return state;
  }
};

export default homePageReducer;
