import { MadeToMeasureAltiformMeasurements } from 'components/pages/madeToMeasure/altiform/types';
import { MadeToMeasureAltipressMeasurements } from 'components/pages/madeToMeasure/altipress/types';
import { MadeToMeasureAltivenMeasurements } from 'components/pages/madeToMeasure/altiven/types';
import { ReadyToWearAltiformMeasurements } from 'components/pages/readyToWearMeasurementForm/altiform/AltiformReadyToWear';
import { ReadyToWearAltipressMeasurements } from 'components/pages/readyToWearMeasurementForm/altipress/types';
import { ReadyToWearAltivenMeasurements } from 'components/pages/readyToWearMeasurementForm/altiven/types';
import { M2MProduct, RTWProduct } from 'services/hooks/useDownloadProducts';

export enum Brand {
  ALTIFORM = 'Altiform',
  ALTIVEN = 'Altiven Stocking',
  KSOFT = 'K-Soft',
  URGOKTWO = 'UrgoKTwo',
  ALTIPRESS = 'Altipress',
}

export enum SizeCategoryType {
  READY_TO_WEAR = 'ready_to_wear',
  MADE_TO_MEASURE = 'made_to_measure',
}

export enum ToeStyle {
  OPEN = 'Open Toe',
  CLOSED = 'Closed Toe',
}

export enum LengthStyle {
  BELOW_KNEE = 'Below Knee',
  THIGH_LENGTH = 'Thigh High',
  THIGH_LENGTH_HOLD_UP = 'Thigh High Hold-up',
  THIGH_LENGTH_HOLD_UP_LACE = 'Thigh High Hold-up (lace)',
  THIGH_LENGTH_WAIST = 'Thigh With Waist Attachment',
  TIGHTS = 'Tights',
}

export enum Colour {
  BEIGE = 'Soft Beige',
  BLACK = 'Black',
}

export interface StyleOptions {
  toeStyle: ToeStyle;
  lengthStyle: LengthStyle;
  colour: Colour;
}

export enum AltipressInnerColour {
  WHITE = 'White',
  BEIGE = 'Soft Beige',
}

export interface AltipressStyleOptions {
  outerColour?: Colour;
  innerColour: AltipressInnerColour;
}

export enum ProductSizeAltiform {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'X-Large',
}

export enum ProductSizeAltiven {
  SIZE_1 = 'I',
  SIZE_2 = 'II',
  SIZE_3 = 'III',
  SIZE_4 = 'IV',
  SIZE_5 = 'V',
  SIZE_6 = 'VI',
  SIZE_7 = 'VII',
}

export enum ProductSizeAltipress {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'XLarge',
  XXLARGE = 'XXLarge',
}

export enum AltivenLength {
  SHORT = 'Short',
  REGULAR = 'Regular',
}

export enum AltipressLength {
  SHORT = 'Short',
  REGULAR = 'Regular',
  LONG = 'Long',
}

export enum CompressionClass {
  LOW_COMPRESSION = 'Low Compression',
  CLASS_1 = 'Class I',
  CLASS_2 = 'Class II',
  CLASS_3 = 'Class III',
  ALTIPRESS_LEG_ULCER_40_KIT = 'Leg Ulcer Kit',
  ALTIPRESS_LINER_PACK = 'Liner pack',
}

export enum LatexType {
  LATEX_FREE = 'latex_free',
  LATEX = 'latex',
}

export enum BandageCompressionLevel {
  REDUCED = 'reduced',
  FULL = 'full',
}
export interface BandageInfo {
  latexType: LatexType;
  compressionLevel: BandageCompressionLevel;
  ankleCircumference: number;
}

export enum LegType {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export type AllReadyToWearMeasurements =
  | ReadyToWearAltiformMeasurements
  | ReadyToWearAltivenMeasurements
  | ReadyToWearAltipressMeasurements;

export type AllMadeToMeasureMeasurements =
  | MadeToMeasureAltiformMeasurements
  | MadeToMeasureAltivenMeasurements
  | MadeToMeasureAltipressMeasurements;

export const BOTH_LEGS = 'both_legs';

export interface ReadyToWearMeasurementsPayload {
  [LegType.LEFT]?: AllReadyToWearMeasurements;
  [LegType.RIGHT]?: AllReadyToWearMeasurements;
  selectedLeg: LegType | typeof BOTH_LEGS;
}
export interface MadeToMeasureMeasurementsPayload {
  [LegType.LEFT]?: AllMadeToMeasureMeasurements;
  [LegType.RIGHT]?: AllMadeToMeasureMeasurements;
  selectedLeg: LegType | typeof BOTH_LEGS;
}

export type ReadyToWearMeasurementsInfo = ReadyToWearMeasurementsPayload & {
  type: SizeCategoryType.READY_TO_WEAR;
};

export type MadeToMeasureMeasurementsInfo = MadeToMeasureMeasurementsPayload & {
  type: SizeCategoryType.MADE_TO_MEASURE;
};

export type ProductSizes = ProductSizeAltiform[] | ProductSizeAltiven[] | ProductSizeAltipress[];
export type ProductLengths = AltivenLength[] | AltipressLength[];

export type MeasurementsInfo = ReadyToWearMeasurementsInfo | MadeToMeasureMeasurementsInfo;
export interface ProductInfo {
  brand?: Brand;
  styleOptions?: StyleOptions;
  altipressStyleOptions?: AltipressStyleOptions;
  compressionClass?: CompressionClass;
  measurements?: MeasurementsInfo;
  sizeCategory?: SizeCategoryType;
  bandageInfo?: BandageInfo;
  productSizes: ProductSizes;
  productLengths: ProductLengths;
  realProduct?: RTWProduct | M2MProduct;
  quantity?: number;
}

export const CLEAR_PRODUCT_INFO = 'CLEAR_PRODUCT_INFO';
export const SET_BRAND = 'SET_BRAND';
export const CLEAR_BRAND = 'CLEAR_BRAND';
export const SET_SIZE_CATEGORY = 'SET_SIZE_CATEGORY';
export const CLEAR_SIZE_CATEGORY = 'CLEAR_SIZE_CATEGORY';
export const SET_STYLE_OPTIONS = 'SET_STYLE_OPTIONS';
export const SET_ALTIPRESS_STYLE_OPTIONS = 'SET_ALTIPRESS_STYLE_OPTIONS';
export const SET_READY_TO_WEAR_MEASUREMENTS = 'SET_READY_TO_WEAR_MEASUREMENTS';
export const SET_MADE_TO_MEASURE_MEASUREMENTS = 'SET_MADE_TO_MEASURE_MEASUREMENTS';
export const SET_PRODUCT_SIZES = 'SET_PRODUCT_SIZES';
export const SET_PRODUCT_LENGTHS = 'SET_PRODUCT_LENGTHS';
export const SET_COMPRESSION_CLASS = 'SET_COMPRESSION_CLASS';
export const SET_BANDAGE_INFO = 'SET_BANDAGE_INFO';
export const SET_REAL_PRODUCT = 'SET_REAL_PRODUCT';
export const SET_QUANTITY = 'SET_QUANTITY';

interface SetQuantity {
  type: typeof SET_QUANTITY;
  payload: number;
}
interface SetRealProduct {
  type: typeof SET_REAL_PRODUCT;
  payload: RTWProduct | M2MProduct;
}

interface ClearProductInfo {
  type: typeof CLEAR_PRODUCT_INFO;
}

interface SetBrand {
  type: typeof SET_BRAND;
  payload: Brand;
}

interface ClearBrand {
  type: typeof CLEAR_BRAND;
}

interface SetSizeCategory {
  type: typeof SET_SIZE_CATEGORY;
  payload: SizeCategoryType;
}

interface ClearSizeCategory {
  type: typeof CLEAR_SIZE_CATEGORY;
}

interface SetStyleOptions {
  type: typeof SET_STYLE_OPTIONS;
  payload: StyleOptions;
}

interface SetAltipressStyleOptions {
  type: typeof SET_ALTIPRESS_STYLE_OPTIONS;
  payload: AltipressStyleOptions;
}

interface SetCompressionClass {
  type: typeof SET_COMPRESSION_CLASS;
  payload: CompressionClass;
}

interface SetReadyToWearMeasurements {
  type: typeof SET_READY_TO_WEAR_MEASUREMENTS;
  payload: ReadyToWearMeasurementsPayload;
}

interface SetMadeToMeasureMeasurements {
  type: typeof SET_MADE_TO_MEASURE_MEASUREMENTS;
  payload: MadeToMeasureMeasurementsPayload;
}

interface SetProductSizes {
  type: typeof SET_PRODUCT_SIZES;
  payload: ProductSizes;
}

interface SetProductLengths {
  type: typeof SET_PRODUCT_LENGTHS;
  payload: ProductLengths;
}

interface SetBandageInfo {
  type: typeof SET_BANDAGE_INFO;
  payload: BandageInfo;
}

export type ProductInfoActionTypes =
  | ClearProductInfo
  | SetBrand
  | ClearBrand
  | SetStyleOptions
  | SetAltipressStyleOptions
  | SetCompressionClass
  | SetSizeCategory
  | ClearSizeCategory
  | SetReadyToWearMeasurements
  | SetMadeToMeasureMeasurements
  | SetProductLengths
  | SetProductSizes
  | SetBandageInfo
  | SetRealProduct
  | SetQuantity;
