import {
  CLEAR_BRAND,
  CLEAR_PRODUCT_INFO,
  CLEAR_SIZE_CATEGORY,
  ProductInfo,
  ProductInfoActionTypes,
  SET_ALTIPRESS_STYLE_OPTIONS,
  SET_BANDAGE_INFO,
  SET_BRAND,
  SET_COMPRESSION_CLASS,
  SET_MADE_TO_MEASURE_MEASUREMENTS,
  SET_PRODUCT_LENGTHS,
  SET_PRODUCT_SIZES,
  SET_QUANTITY,
  SET_READY_TO_WEAR_MEASUREMENTS,
  SET_REAL_PRODUCT,
  SET_SIZE_CATEGORY,
  SET_STYLE_OPTIONS,
  SizeCategoryType,
} from './types';

export const initialState: ProductInfo = {
  productSizes: [],
  productLengths: [],
};

const productInfoReducer = (
  state: ProductInfo = initialState,
  action: ProductInfoActionTypes,
): ProductInfo => {
  const typedAction = action;
  switch (typedAction.type) {
    case CLEAR_PRODUCT_INFO:
      return {
        ...initialState,
      };
    case SET_BRAND:
      return {
        ...state,
        brand: typedAction.payload,
      };
    case CLEAR_BRAND:
      return {
        ...state,
        brand: undefined,
      };
    case SET_SIZE_CATEGORY:
      return {
        ...state,
        sizeCategory: typedAction.payload,
      };
    case CLEAR_SIZE_CATEGORY:
      return {
        ...state,
        sizeCategory: undefined,
      };
    case SET_STYLE_OPTIONS:
      return {
        ...state,
        styleOptions: typedAction.payload,
        altipressStyleOptions: undefined,
      };
    case SET_ALTIPRESS_STYLE_OPTIONS:
      return {
        ...state,
        altipressStyleOptions: typedAction.payload,
        styleOptions: undefined,
      };
    case SET_COMPRESSION_CLASS:
      return {
        ...state,
        compressionClass: typedAction.payload,
      };
    case SET_BANDAGE_INFO:
      return {
        ...state,
        bandageInfo: typedAction.payload,
      };
    case SET_READY_TO_WEAR_MEASUREMENTS:
      return {
        ...state,
        measurements: {
          type: SizeCategoryType.READY_TO_WEAR,
          ...typedAction.payload,
        },
      };
    case SET_MADE_TO_MEASURE_MEASUREMENTS:
      return {
        ...state,
        measurements: {
          type: SizeCategoryType.MADE_TO_MEASURE,
          ...typedAction.payload,
        },
      };
    case SET_PRODUCT_SIZES:
      return {
        ...state,
        productSizes: typedAction.payload,
      };
    case SET_PRODUCT_LENGTHS:
      return {
        ...state,
        productLengths: typedAction.payload,
      };
    case SET_REAL_PRODUCT:
      return {
        ...state,
        realProduct: typedAction.payload,
      };
    case SET_QUANTITY:
      return {
        ...state,
        quantity: typedAction.payload,
      };
    default:
      return state;
  }
};

export default productInfoReducer;
