import { SET_TRANSLATOR_MODE, TranslatorModeActionTypes, TranslatorModeState } from './types';

export const initialState: TranslatorModeState = false;

const translatorModeReducer = (
  state: TranslatorModeState = initialState,
  action: TranslatorModeActionTypes,
): TranslatorModeState => {
  const typedAction = action;
  switch (typedAction.type) {
    case SET_TRANSLATOR_MODE:
      return typedAction.payload;
    default:
      return state;
  }
};

export default translatorModeReducer;
