import { ActionType, getType } from 'typesafe-actions';
import { AnyAction } from 'redux';

import { hydrate } from '../actions';
import { loginUser, logoutUser } from './actions';

export type LoginAction = ActionType<
  | typeof loginUser.request
  | typeof loginUser.success
  | typeof loginUser.failure
  | typeof logoutUser
  | typeof hydrate
>;

export type LoginState = Readonly<{
  token: string | null;
  loginError: string | null;
}>;

export const initialState: LoginState = { token: null, loginError: null };

const reducer = (state: LoginState = initialState, action: AnyAction): LoginState => {
  const typedAction = action as LoginAction;
  switch (typedAction.type) {
    case getType(loginUser.request):
      return {
        ...state,
        loginError: null,
      };
    case getType(loginUser.success):
      return {
        ...state,
        token: typedAction.payload.token,
        loginError: null,
      };
    case getType(loginUser.failure):
      return {
        ...state,
        loginError: typedAction.payload.errorMessage,
      };
    case logoutUser.type:
      return initialState;
    case getType(hydrate):
      return { ...state, ...typedAction.payload.login };
    default:
      return state;
  }
};

export default reducer;
