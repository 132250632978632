import styled from 'styled-components';
import { borderRadius, colorPalette, getSpacing } from 'stylesheet';
import { Button as MuiButton } from '@material-ui/core';

export const Button = styled(MuiButton)`
  background: ${colorPalette.blueBright};
  color: white;
  padding: ${getSpacing(1)} ${getSpacing(3)};
  border-radius: ${borderRadius.medium};
  width: 100%;

  :hover {
    background: ${colorPalette.blueDark};
  }
`;
