import { OEDEMA_TYPE } from 'components/pages/oedema/constants';
import { ADDITIONAL_CONDITIONS } from 'components/pages/prevention/constants';
import { Brand } from 'redux/ProductInfo/types';
import {
  CLEAR_CONDITION,
  CompressionReasonType,
  ConditionActionTypes,
  SET_COMPRESSION_REASON,
  SET_FILTERED_PRODUCTS,
  SET_OEDEMA_LEVEL,
  SET_PREVENTION_REASON,
  SET_SELF_CARE,
  SET_SKIN_FOLDS,
} from './types';

export const clearCondition = (): ConditionActionTypes => ({
  type: CLEAR_CONDITION,
});

export const setCompressionReason = (
  compressionReason: CompressionReasonType,
): ConditionActionTypes => ({
  type: SET_COMPRESSION_REASON,
  payload: compressionReason,
});

export const setPreventionReason = (
  preventionReason: ADDITIONAL_CONDITIONS,
): ConditionActionTypes => ({
  type: SET_PREVENTION_REASON,
  payload: preventionReason,
});

export const setFilteredProducts = (filteredProducts: Brand[]): ConditionActionTypes => ({
  type: SET_FILTERED_PRODUCTS,
  payload: filteredProducts,
});

export const setOedemaLevel = (oedemaLevel: OEDEMA_TYPE): ConditionActionTypes => ({
  type: SET_OEDEMA_LEVEL,
  payload: oedemaLevel,
});

export const setSelfCare = (selfCare: boolean): ConditionActionTypes => ({
  type: SET_SELF_CARE,
  payload: selfCare,
});

export const setSkinFolds = (skinFolds: boolean): ConditionActionTypes => ({
  type: SET_SKIN_FOLDS,
  payload: skinFolds,
});

export default {
  clearCondition,
  setCompressionReason,
  setPreventionReason,
  setFilteredProducts,
  setOedemaLevel,
  setSkinFolds,
};
